import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { isFutureSet, STATS_PLACEHOLDER_SET } from "@/game-tft/constants.mjs";
import {
  Search,
  SelectGameMode,
  SelectRank,
  SelectRegion,
} from "@/game-tft/Selects.jsx";
import SetTile from "@/game-tft/SetTile.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import { setTotalGames } from "@/game-tft/StatsAugments.jsx";
import StatsTooltip from "@/game-tft/StatsTooltip.jsx";
import TraitTooltip from "@/game-tft/TraitTooltip.jsx";
import TypeIcon from "@/game-tft/TypeIcon.jsx";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import { translateTraits, useTraitsByParams } from "@/game-tft/use-traits.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { FlexContainer } from "@/shared/InfiniteTable.style.jsx";
import { getLocale, getLocaleString } from "@/util/i18n-helper.mjs";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";

const columns = [
  {
    label: ["common:navigation.traits", "Traits"],
    dataKey: "trait",
    align: "left",
    primary: true,
  },
  {
    label: ["common:avgPlace", "Avg. Place"],
    dataKey: "avg_placement",
    align: "right",
    isStat: true,
    isReverse: true,
  },
  {
    label: ["tft:topFour", "Top 4"],
    dataKey: "top_4_percent",
    align: "right",
    isStat: true,
  },
  {
    label: ["lol:winRate", "Win Rate"],
    dataKey: "top_1_percent",
    align: "right",
    isStat: true,
  },
  {
    label: ["tft:pickRate", "Pick Rate"],
    labelSub: ["tft:table.caption.total", "{{count}} Games", { count: 0 }],
    dataKey: "pick_rate",
    align: "right",
    isStat: true,
  },
];

function StatsTraits() {
  const { searchParams } = useRoute();
  const set = useSetByParam(true);
  const { result, data } = useTraitsByParams("traits", isFutureSet(set));
  let traits = result;
  const search = searchParams.get("search");
  const { t } = useTranslation();
  const locale = getLocale();
  const [tableCol, setTableCol] = useState(columns);
  const isLoaded = useIsLoaded();

  // search filter
  if (search?.length > 1) {
    const instance = RegExp(search, "i");
    traits = (Array.isArray(traits) ? traits : []).filter(({ key }) =>
      instance.test(key),
    );
  }

  // Total Games
  useEffect(() => {
    setTotalGames(data, setTableCol);
  }, [data]);

  // Renderers
  function colRenderer({ dataKey, rowData, rowIndex }) {
    if (dataKey === "rank") {
      return <div className="type-body2">{rowIndex + 1}</div>;
    }
    if (dataKey === "trait") {
      const traitKey = StaticTFT.getTraitKey(rowData.apiKey, set);
      return (
        <Trait>
          <TraitTooltip
            name={rowData.apiKey}
            set={set}
            count={rowData.count}
            color={rowData.color}
          >
            <TypeIcon
              name={traitKey}
              traitMetColor={rowData.color}
              size={32}
              type="darker"
            />
            <div className="type-body2">
              {translateTraits(t, traitKey, set)}{" "}
              {rowData.count ? (
                <span className="trait-count">{`x${rowData.count}`}</span>
              ) : null}
            </div>
          </TraitTooltip>
        </Trait>
      );
    }
    if (
      dataKey === "avg_placement" &&
      typeof rowData.avg_placement === "number"
    ) {
      return rowData.avg_placement.toLocaleString(locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    }
    if (
      dataKey === "top_4_percent" &&
      typeof rowData.top_4_percent === "number"
    ) {
      return (
        <div className="type-body2">
          {t("lol:percent", "{{number}}%", {
            number: (rowData.top_4_percent * 100).toLocaleString(locale, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          })}
        </div>
      );
    }
    if (
      dataKey === "top_1_percent" &&
      typeof rowData.top_1_percent === "number"
    ) {
      return (
        <div className="type-body2">
          {getLocaleString(rowData.top_1_percent, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          })}
        </div>
      );
    }
    if (dataKey === "pick_rate" && typeof rowData.pick_rate === "number") {
      return (
        <div className="type-body2">
          {t("lol:percent", "{{number}}%", {
            number: (rowData.pick_rate * 100).toLocaleString(locale, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }),
          })}
        </div>
      );
    }
    // if (
    //   dataKey === "matchesPlayed" &&
    //   typeof rowData.matchesPlayed === "number"
    // ) {
    //   return (
    //     <div className="type-body2">
    //       {rowData.matchesPlayed.toLocaleString(locale, {
    //         minimumFractionDigits: 0,
    //         maximumFractionDigits: 0,
    //       })}
    //     </div>
    //   );
    // }
    return null;
  }

  if (!traits?.length && set === STATS_PLACEHOLDER_SET)
    return <SetTile page="Statistics" />;

  if (!Array.isArray(traits) || !traits?.length) return "";

  const cols = tableCol.map((col) => {
    return {
      display: col.labelSub ? (
        <StatsTooltip
          statKey={
            col.dataKey === "pick_rate" ? "pick_rate_traits" : col.dataKey
          }
        >
          <div className="flex column align-center gap-1">
            <div>{t(col.label[0], col.label[1])}</div>
            <div className="shade3">
              {t(col.labelSub[0], col.labelSub[1], col.labelSub[2])}
            </div>
          </div>
        </StatsTooltip>
      ) : (
        <StatsTooltip
          statKey={
            col.dataKey === "pick_rate" ? "pick_rate_traits" : col.dataKey
          }
        >
          {t(col.label[0], col.label[1])}
        </StatsTooltip>
      ),
      align: col.align,
      primary: col.primary,
      isStat: col.isStat,
    };
  });
  const rows = traits.map((item, i) => {
    return tableCol.map((col) => {
      return {
        display: colRenderer({
          dataKey: col.dataKey,
          rowData: item,
          rowIndex: i,
        }),
        value: col.isReverse ? 1 / item[col.dataKey] : item[col.dataKey],
        className: col.className,
      };
    });
  });

  // Render
  return (
    <FlexContainer $opacity={isLoaded ? 1 : 0.5}>
      <TraitsTable>
        <DataTable
          cols={cols}
          rows={rows}
          sortDir="DESC"
          sortCol={1}
          indexCol
        />
      </TraitsTable>
    </FlexContainer>
  );
}

const TraitsTable = styled("div")`
  .cell {
    padding-left: 0 !important;
    margin-left: 0.75rem;
  }
  thead {
    height: 3rem !important;
  }
`;

function StatsTraitsFilterBar() {
  const { FilterBar } = useTraitsStats();
  return FilterBar;
}

export default {
  View: StatsTraits,
  FilterBar: StatsTraitsFilterBar,
};

export function meta() {
  return {
    title: [
      "tft:helmet.statistics.traits.title",
      "TFT Traits Statistics – Blitz TFT",
    ],
    description: [
      "tft:helmet.statistics.traits.description",
      "Teamfight Tactics TFT Traits Statistics, Overview, Classes & origins, Traits, Synergies, Best Comps, Tierlist.",
    ],
  };
}

function useTraitsStats() {
  const { t } = useTranslation();
  const set = useSetByParam(true);
  const { searchParams } = useRoute();
  const isHyperRoll = searchParams.get("mode") === "hyperroll";
  const View = useMemo(() => {
    return (
      <>
        <Search placeholder={t("tft:searchTraits", "Search Traits")} />
        {!isFutureSet(set) && (
          <>
            <SelectRegion />
            <SelectGameMode />
            <SelectRank isHyperRoll={isHyperRoll} />
          </>
        )}
      </>
    );
  }, [t, set, isHyperRoll]);
  return {
    FilterBar: View,
  };
}

const Trait = styled("div")`
  > div {
    display: flex;
    align-items: center;
    gap: var(--sp-4);

    .trait-count {
      color: var(--shade2);
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
