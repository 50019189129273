import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import SelectSet from "@/game-tft/SelectSet.jsx";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import { FlexContainer } from "@/shared/InfiniteTable.style.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";

const StatsHeader = styled("div")`
  ${tablet} {
    .inner-container {
      flex-direction: column;
      align-items: flex-start;

      .right {
        width: 50%;
      }

      ${mobile} {
        align-items: center;
        .right {
          width: 100%;
          padding: 0 10%;

          .selectButton .text {
            max-width: 100%;
          }
        }
      }
    }
  }
`;

const StatsCard = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
`;

const QUEUE_TYPES = {
  ALL: "all",
  LEGEND: "LEGEND",
  AUGMENT: "AUGMENT",
  DUOS: "DUOS",
  TRIOS: "TRIOS",
};
const TABS = {
  LEGENDS: "legends",
  AUGMENTS: "augments",
};

function Buttons() {
  const { t } = useTranslation();
  const {
    parameters: [tab, page],
    searchParams,
  } = useRoute();
  const typeParam = searchParams.get("type") ?? QUEUE_TYPES.ALL;
  const type = typeParam.toLowerCase();
  const set = useSetByParam(true, true);
  const buttons = (tab) => {
    if (tab === TABS.LEGENDS)
      return [
        {
          text: t("common:all", "All"),
          url: `/tft/legends/stats?set=${set}`,
          queueType: QUEUE_TYPES.ALL,
        },
        {
          text: t("tft:legendsOnly", "Legends Only"),
          url: `/tft/legends/stats?set=${set}&type=${QUEUE_TYPES.LEGEND}`,
          queueType: QUEUE_TYPES.LEGEND,
        },
        {
          text: t("tft:augmentsOnly", "Augments Only"),
          url: `/tft/legends/stats?set=${set}&type=${QUEUE_TYPES.AUGMENT}`,
          queueType: QUEUE_TYPES.AUGMENT,
        },
      ];

    if (tab === TABS.AUGMENTS)
      return [
        {
          text: t("common:all", "All"),
          url: `/tft/augments/stats?set=${set}`,
          queueType: QUEUE_TYPES.ALL,
        },
        // {
        //   text: t("tft:legend", "Legend"),
        //   url: `/tft/augments/stats?set=${set}&type=${QUEUE_TYPES.LEGEND}`,
        //   queueType: QUEUE_TYPES.LEGEND,
        // },
        {
          text: t("tft:duos", "Duos"),
          url: `/tft/augments/stats?set=${set}&type=${QUEUE_TYPES.DUOS}`,
          queueType: QUEUE_TYPES.DUOS,
        },
        {
          text: t("tft:trios", "Trios"),
          url: `/tft/augments/stats?set=${set}&type=${QUEUE_TYPES.TRIOS}`,
          queueType: QUEUE_TYPES.TRIOS,
        },
      ];

    return [
      {
        text: t("common:tab.stats", "Stats"),
        url: `/tft/${tab}/stats?set=${set}`,
      },
      {
        text: t("lol:overview", "Overview"),
        url: `/tft/${tab}/overview?set=${set}`,
      },
    ];
  };

  const rightButtons = buttons(tab);

  return (
    <ButtonGroup>
      {rightButtons.map(({ text, url, queueType }, idx) => {
        const isActive = () => {
          if ((tab === TABS.LEGENDS || tab === TABS.AUGMENTS) && queueType)
            return queueType === type;
          return url.toLowerCase().includes(page.toLowerCase());
        };
        return (
          <Button key={idx} active={isActive()} href={url}>
            {text}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

function StatsOverviewLayout({ title, filters, nowrap, children }) {
  const {
    parameters: [, page],
  } = useRoute();
  const { t } = useTranslation();
  const isLoaded = useIsLoaded();
  const set = useSetByParam(true, true);

  const getLinks = useCallback(
    (page) => {
      return [
        {
          text: t("lol:champion_plural", "Champions"),
          url: `/tft/champions/${page}?set=${set}`,
          routeMatch: /champions/,
        },
        {
          text: t("tft:common.traits", "Traits"),
          url: `/tft/traits/${page}?set=${set}`,
          routeMatch: /traits/,
        },
        {
          text: t("common:navigation.items", "Items"),
          url: `/tft/items/${page}?set=${set}`,
          routeMatch: /items/,
        },
        {
          text: t("common:navigation.augments", "Augments"),
          url: `/tft/augments/stats?set=${set}`,
          routeMatch: /augments/,
        },
        // {
        //   text: t("common:navigation.legends", "Legends"),
        //   url: `/tft/legends/stats?set=${set}`,
        //   routeMatch: /legends/,
        // },
      ];
    },
    [set, t],
  );

  const links = useMemo(() => getLinks(page), [page, getLinks]);

  return (
    <PageContainer>
      <StatsHeader>
        <PageHeader title={title} links={links} aside={<SelectSet />} />
      </StatsHeader>
      <CardNavigation>
        <CardNavigationLeft>{filters ? filters : null}</CardNavigationLeft>
        <Buttons />
      </CardNavigation>
      <FlexContainer $opacity={isLoaded ? 1 : 0.5}>
        {nowrap ? (
          <NoWrap className="flex">
            <StatsCard>
              <Card padding={0}></Card>
            </StatsCard>
            {children}
          </NoWrap>
        ) : (
          <StatsCard>
            <Card padding={0}>
              <div>{children}</div>
            </Card>
          </StatsCard>
        )}
      </FlexContainer>
    </PageContainer>
  );
}

export default StatsOverviewLayout;

const CardNavigation = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--sp-4);
  flex-flow: wrap;
`;

const CardNavigationLeft = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-2);
  flex-flow: wrap;
`;

const NoWrap = styled("div")`
  gap: var(--sp-4);
  flex-direction: column;
`;
